import React from "react";
import {
  DateRangePicker as DateRangePickerRaw,
  isInclusivelyAfterDay,
} from "react-dates";
import { START_DATE, END_DATE } from "react-dates/constants";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import moment from "moment";
import "moment/locale/ru";

class DateRangePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      startDate: null,
      endDate: null,
      startDateError: "",
      endDateError: "",
    };
  }

  handleDatesChange = ({ startDate, endDate }) => {
    let { focusedInput } = this.state;
    const maxLengthStartDate = 10; // Length of 'DD.MM.YYYY' format
    const timeout = 500;

    if (focusedInput === START_DATE && startDate) {
      const startDateString = startDate.format("DD.MM.YYYY");
      if (startDateString.length === maxLengthStartDate) {
        focusedInput = END_DATE;
      }
    }

    this.setState({ focusedInput, startDate, endDate });

    if (this.props.onChange) {
      this.props.onChange(startDate, endDate);
    }

    if (this.startDateTimeout) {
      clearTimeout(this.startDateTimeout);
    }

    this.startDateTimeout = setTimeout(() => {
      this.validateStartDate();
    }, timeout);

    if (this.endDateTimeout) {
      clearTimeout(this.endDateTimeout);
    }

    this.endDateTimeout = setTimeout(() => {
      this.validateEndDate();
    }, timeout);
  };

  handleFocusChange = (focusedInput) => {
    this.setState({ focusedInput });
  };

  handleBlur = () => {
    setTimeout(() => {
      const { startDate, endDate } = this.state;

      if (!endDate) {
        return;
      }

      if (this.props.onBlur) {
        this.props.onBlur(startDate, endDate);
      }
    }, 100);
  };

  validateStartDate = () => {
    const { startDate } = this.state;

    if (!startDate) {
      this.setState({
        startDateError: "Дата должна быть в формате ДД.ММ.ГГГГ",
      });
    } else {
      const startDateString = startDate.format("DD.MM.YYYY");
      const dateFormat = /^\d{2}\.\d{2}\.\d{4}$/;

      if (!dateFormat.test(startDateString)) {
        this.setState({
          startDateError: "Дата должна быть в формате ДД.ММ.ГГГГ",
        });
      } else {
        this.setState({ startDateError: "" });
      }
    }
  };

  validateEndDate = () => {
    const { endDate } = this.state;

    if (!endDate) {
      this.setState({ endDateError: "Дата должна быть в формате ДД.ММ.ГГГГ" });
    } else {
      const endDateString = endDate.format("DD.MM.YYYY");
      const dateFormat = /^\d{2}\.\d{2}\.\d{4}$/;

      if (!dateFormat.test(endDateString)) {
        this.setState({
          endDateError: "Дата должна быть в формате ДД.ММ.ГГГГ",
        });
      } else {
        this.setState({ endDateError: "" });
      }
    }
  };

  render() {
    const { focusedInput, startDate, endDate, startDateError, endDateError } =
      this.state;
    const { id, className } = this.props;
    const style = {
      color: "red",
      fontSize: "12px",
      marginTop: "5px",
      position: "absolute",
    };

    return (
      <div id={id} className={className} style={{ position: "relative" }}>
        <DateRangePickerRaw
          onClose={this.handleBlur}
          onDatesChange={this.handleDatesChange}
          onFocusChange={this.handleFocusChange}
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
          startDatePlaceholderText="Начало"
          endDatePlaceholderText="Конец"
          minimumNights={0}
          numberOfMonths={1}
          hideKeyboardShortcutsPanel
          startDateId={`${id}-start`}
          endDateId={`${id}-end`}
          isOutsideRange={(day) =>
            isInclusivelyAfterDay(day, moment().add(1, "d"))
          }
        />
        {startDateError ? (
          <div style={style}>{startDateError}</div>
        ) : (
          endDateError && <div style={style}>{endDateError}</div>
        )}
      </div>
    );
  }
}

export default DateRangePicker;
